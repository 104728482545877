import React from 'react'
import links from 'links'
import { track } from 'analytics'
import { usePathname } from 'router'

import { constants, cx } from 'helpers'

import { useDevice } from 'device'
import { useAb } from 'hooks'
import { useUser } from 'modules/user'

import { Href } from 'components/navigation'
import { Logo, Text } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'

import LandingSubscriptionHeaderBanner from 'compositions/banners/LandingSubscriptionHeaderBanner/LandingSubscriptionHeaderBanner'
import TwelveDaysBanner from 'compositions/banners/TwelveDaysBanner/TwelveDaysBanner'
import Header from 'compositions/Header/Header'
import { MainHeader } from 'compositions/Header/components'
import Footer from 'compositions/Footer/Footer'


const LandingLayout: React.CFC<{ forceFullLayout?: boolean }> = ({ children, forceFullLayout }) => {
  const pathname = usePathname()
  const { isMobile } = useDevice()

  const { user, isLoggedIn } = useUser()
  const isLead = !isLoggedIn || user?.analyticsMetadata?.subscriptionStatus === 'LEAD'

  const isHomePage = (
    pathname === links.home
    || pathname === links.homeMen
  )
  const isTwelveDaysPages = pathname === links.twelveDays
  const isFullLayout = forceFullLayout ?? (isHomePage || isTwelveDaysPages)

  const isTryNowLinkAbEnabled = useAb(constants.abTests.headerTryNowLink) === 'b'

  // TODO: Extract this to a separate component and reuse in Header and here if CRO-627 ab test wins
  const tryNowLinkNode = (
    <Href
      className={cx(!isMobile && 'bg-dark-red rounded-[35rem] px-24 py-8')}
      to={isLoggedIn ? links.subscribe : `${links.register}?redirect=${links.subscribe}`}
      onClick={() => {
        track('Try now button click', {
          placement: 'header',
        })
      }}
      data-testid="tryNowButton"
    >
      <Text message={buttonMessages.tryNow} style="t2" />
    </Href>
  )

  const logInLinkNode = (
    <Href
      to={`${links.login}?redirect=${encodeURIComponent(links.subscribe)}`}
      onClick={() => {
        track('Login button click')
      }}
      data-testid="loginButton"
    >
      <Text tag="span" message={buttonMessages.login} style="t2" />
    </Href>
  )

  let rightSideContent
  if (isTryNowLinkAbEnabled) {
    if (isMobile) {
      rightSideContent = !isLoggedIn && (
        tryNowLinkNode
      )
    }
    else {
      rightSideContent = (
        <div className="flex items-center gap-12">
          {
            !isLoggedIn && (
              logInLinkNode
            )
          }
          {
            isLead && (
              tryNowLinkNode
            )
          }
        </div>
      )
    }
  }
  else {
    rightSideContent = !isLoggedIn && (
      logInLinkNode
    )
  }

  return (
    <div className="flex min-h-full flex-col">
      {
        !isFullLayout && (
          <LandingSubscriptionHeaderBanner />
        )
      }
      {
        isTwelveDaysPages && (
          <TwelveDaysBanner />
        )
      }
      {
        isFullLayout ? (
          <Header
            logoLink={null}
            withSubHeader={false}
            withSupNav={false}
            // TODO: Implement "landing" flag for Strapi top bar banners — added on 24–03–2025
            // withBanner={isBlackFridayBannerVisible || isCyberMondayBannerVisible}
          />
        ) : (
          <MainHeader>
            <div className="flex-1" /> {/* flex placeholder */}
            <Logo className="flex-1 text-center" color="white" />
            <div className="mb-4 flex flex-1 justify-end text-right">
              {rightSideContent}
            </div>
          </MainHeader>
        )
      }
      <main id="main" className="flex-auto">
        {children}
      </main>
      <Footer />
    </div>
  )
}


export default LandingLayout
